.button_default{
    background: #ca2a38 !important;
    color: white !important;
    border: 1px solid #ca2a38 !important;
    &:hover{
        background: #ca2a38c4 !important;
        border: 1px solid #ca2a38 !important;
    }
}
.button_outlined{
    background: white !important;
    color: #ca2a38 !important;
    border: 1px solid #ca2a38 !important;
    &:hover{
        background: white !important;
        color: #ca2a38c4 !important;
        border: 1px solid #ca2a38c4 !important;
    }
}