.select_visable{
    display: none !important;
}
.head_table_select{
    display: none !important;
}
.head_table_row{
    // border-right: 1px solid #e1e1e1;s
    border-top: 1px solid #e1e1e1;

}
@media screen and (max-width: 920px) {
    .select_visable{
        display: flex !important;
        margin-right: 10px;
    }
    .head_table_select{
        width: auto;
        padding: none; 
        display: table-cell !important;
        padding-bottom: 15px;
      }
      .head_table_row{
          display: none !important;
      }
  }