.modal_content_wrapper {
	background: #f6f6f6;
	overflow: hidden;
	min-width: 320px;
}
.table_container {
	background-image: linear-gradient(
		to bottom,
		#109618,
		#4bab13,
		#74bf0c,
		#9dd303,
		#c6e600,
		#dcdc00,
		#efd200,
		#ffc700,
		#fda00b,
		#f37920,
		#e2522e,
		#ca2a38
	);

	tr {
		td:last-child {
			background-color: #fff;
		}
	}
}
.modal_button_block {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
	flex-flow: wrap;
}
.modal_button_block_two {
	justify-content: space-around;
	flex-flow: wrap-reverse;
	button {
		margin: 5px;
	}
}
.modal_wrapper {
	position: relative;
}
.close {
	position: absolute;
	right: 8px;
	top: 6px;
	width: 32px;
	height: 32px;
	opacity: 0.9;
	cursor: pointer;
	transition: all 0.6s ease;
}
.close:hover {
	transform: rotate(90deg);
}
.close:before,
.close:after {
	position: absolute;
	left: 15px;
	content: " ";
	height: 33px;
	width: 4px;
	background-color: #ff4f4f;
}
.close:before {
	transform: rotate(45deg);
}
.close:after {
	transform: rotate(-45deg);
}
@media screen and (max-width: 460px) {
	.modal_content_wrapper {
		min-width: auto;
	}
}
