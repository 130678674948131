@import "../../../../helpers/variable.scss";

@include colorPicker;

.mobile_card_wrapper {
	.mobile_card_row {
		margin: 5px auto 8px;
		display: flex;
	}
	.mobile_card_row_inline {
		flex-direction: inherit;
	}
	.mobile_card_name {
		margin-right: 5px;
		font-weight: bold;
		font-family: "Open Sans";
		white-space: nowrap;
	}
}
.link_style {
	text-decoration: none;
	color: blue;
}
.mobile_version_table {
	display: none !important;
}
.mobile_card_row_column {
	flex-direction: column;
}
.mobile_card_row_minimize_text {
	text-align: center;
	font-size: 20px;

	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}
.mobile_version_table_selected {
	background-color: rgba(110, 110, 110, 0.08);
}
.minus_icon {
	color: #ff4f4f;
}
.plus_icon {
	color: green;
}
.block_icon_img {
	display: flex;
	align-items: center;
	svg {
		margin-right: 10px;
	}
}
@media only screen and (max-width: 810px) {
	.mobile_version_table {
		position: relative;
		display: table-row !important;
		border-top: 1px solid rgba(224, 224, 224, 1);
	}
}
