@import "../../../../helpers/variable.scss";

@include colorPicker;

th {
	&:nth-child(8), /*Код результата*/
	&:nth-child(11) /*Код авторизации*/ {
		span {
			white-space: break-spaces;
			max-width: 105px;
		}
	}
}
