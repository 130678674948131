.more_horiz_menu {
	display: flex;
  justify-content: center;
	position: absolute;
	right: -36px;
	margin-top: 8px;
	padding-top: 3px;
	transition: background-color 200ms, color 200ms;
	border-radius: 2px;
	color: #ca2a38;
	border: 1px solid #ca2a38;
	cursor: pointer;
	z-index: 7;
	&:hover {
		background-color: #ca2a38;
		color: #fff;
	}
	td.more_horiz_menu_container {
		padding: 0;
		border: none;
	}
}
@media (min-width: 840px) and (max-width: 1340px) {
	.more_horiz_menu {
		right: -22px;
		margin-top: 0;
		width: 20px;
	}
}
@media screen and (max-width: 839px) {
	.more_horiz_menu {
		right: 10px;
	}
}
