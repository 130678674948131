.prepare_order_pay_table_toolbar {
	padding-left: 16px;
	padding-right: 8px;
	flex-wrap: wrap;
	gap: 20px;
}
.prepare_order_pay_active_toolbar {
	color: #f50057;
	background-color: rgb(255, 226, 236);
}
.prepare_order_pay_table_title {
	flex: 1 1 100%;
	text-align: center;

	div {
		font-family: default_header;
		font-weight: 100;
		text-transform: uppercase;
	}
}
