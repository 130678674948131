.data_picker_wrapper {
    [class*="react-datepicker__day--selected"] {
        background: #ff4f4f;
        &:focus {
            outline: red 1px;
        }
    }
    [class*="react-datepicker__month--selected"] {
        background: #ff4f4f;
        &:hover {
            background: #ff4f4f;
        }
        &:focus {
            outline: red 1px;
        }
    }
    [class*="react-datepicker__month-container"] {
        font-size: 15px;
    }
    [class*="react-datepicker__day"] {
        &:focus {
            outline: red 1px;
        }
    }
    [class*="react-datepicker__header react-datepicker-year-header"] {
        height: 43px;
        font-size: 22px;
    }
}
@media screen and (max-width: 390px) {
    .data_picker_wrapper {
        button {
            padding: 0 5px;
        }
    }
}
