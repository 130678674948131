.loading_container_wrapper{
    position: absolute;
    width: 100%;
    height:100%;
    background-color: rgba(255, 255, 255, 0.247);
    z-index: 1;
    backdrop-filter: blur(2px);
}
.loading_skeleton{
    height: 100% !important;
}

  