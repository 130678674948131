$bar-width: 20px;
$bar-height: 2px;
$bar-spacing: 7px;

.menu_wrapper {
    outline: 0;
    outline-offset: 0;
    margin-top: 12px;
	cursor: pointer;
}

.hamburger_menu,
.hamburger_menu:after,
.hamburger_menu:before {
  width: $bar-width;
	height: $bar-height;
}

.hamburger_menu {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgb(75, 75, 75);
	transition: all 0ms 300ms;
  
  &.animate {
    background: rgba(255, 255, 255, 0); 
  }
}

.hamburger_menu:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: rgb(75, 75, 75);
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger_menu:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: rgb(75, 75, 75);
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger_menu.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.hamburger_menu.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}
.menu_conatiner_wrapper{
    display: none;
}