@import "../../../../helpers/variable.scss";

@include colorPicker;

th {
	&:nth-child(6) /*Деб. Кол-во*/ {
		span {
			white-space: break-spaces;
			max-width: 80px;
		}
	}
}
