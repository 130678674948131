@import "../../../../helpers/variable.scss";

@include colorPicker;

.block_filter_all_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.current_block_filter_wrapper {
    margin: 0px 2px 5px 3px;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    font-size: 13px;
    line-height: 1;
    vertical-align: top;
    cursor: pointer;
    background: #eee;
    border-radius: 2px;
    -o-transition: background-color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
    &:hover {
        background: #ddd;
    }
}
.current_block_filter_top {
    top: 0 !important;
    &::after {
        top: 6px !important;
    }
}
.current_block_filter {
    padding: 4px 20px 4px 10px;
    height: 18px;
    max-width: 200px;
    display: block;
    top: 3px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    &::after {
        content: "×";
        opacity: 0.5;
        position: absolute;
        top: 3px;
        right: 5px;
        font-size: 15px;
        // speak: none;
    }
    &:hover {
        text-decoration: line-through;
    }
}
.equipment_and_services_button_block {
    padding: 7px 0 15px;
    position: sticky;
    bottom: 0;
    background: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.07);
    z-index: 8;
    text-align: center;
}
.equipment_and_services_table_toolbar {
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    h6 {
        span {
            font-family: default_header;
            font-weight: 100;
            text-transform: uppercase;
        }
    }
}
.equipment_and_services_table_toolbar_selected {
    position: absolute;
    left: 12px;
    font-size: 10px;
    bottom: 0;
    font-weight: bold !important;
    color: #ff4f4f;
}
.equipment_and_services_active_toolbar {
    color: #f50057;
    background-color: rgb(255, 226, 236);
}
.equipment_and_services_pagination {
    flex: 1 1 100%;
}
.equipment_and_services_paper_wrapper {
    margin-bottom: 16px;
}
.equipment_and_services_table_min {
    min-width: 750px;
    position: relative;
    // overflow: hidden;
}
.container_weapper {
    overflow-x: inherit;
}
.equipment_and_services_filter_button {
    position: absolute;
    right: 10px;
}
.equipment_and_services_table_container {
    position: relative;
    td {
        padding: 8px;
        border: 1px solid rgba(224, 224, 224, 1);
    }
}
.minimize_padding {
    td {
        padding: 2px;
        padding-right: 10px;
        border: 1px solid rgba(224, 224, 224, 1);
    }
}
.mobile_card_wrapper {
    .mobile_card_row {
        margin: 8px 8px;
        display: flex;
    }
    .mobile_card_name {
        margin-right: 5px;
        font-weight: bold;

        white-space: nowrap;
    }
}
.mobile_version_table {
    display: none;
}
.head_table_row_align {
    text-align: center;
    border-top: 1px solid #e1e1e1;
    white-space: nowrap;
}
.head_table_checkbox {
    border-top: 1px solid #e1e1e1;
}
.reset_filter {
    cursor: pointer;
}
.paper_wrapper {
    margin: 0 10px 8px;
    th {
        border-top: 1px solid white;
    }
}
@media screen and (max-width: 810px) {
    .mobile_version_table {
        display: table-row;
    }
    .equipment_and_services_table_min {
        min-width: auto;
    }
}
.head_table_checkbox {
    width: 48px;
    padding: 0 0 0 8px;
}
.head_table_row_padding {
    padding: 16px;
}
.head_table_row_none_padding {
    padding: 0;
}
.select_visable {
    display: none !important;
}
@media screen and (max-width: 680px) {
    .head_table_wrapper {
        display: flex;
        flex-direction: column;
    }
    .head_table_checkbox {
        width: auto;
        padding: none;
        display: flex;
        padding-bottom: 15px;
    }
    .head_table_row_none_padding {
        padding: 5px;
    }
    .head_table_row_padding {
        padding: 5px;
    }
    .head_table_row_align {
        text-align: left;
        margin-left: 10px;
        display: none !important;
    }
    .select_visable {
        display: flex !important;
        margin-right: 10px;
    }
}
@media screen and (max-width: 460px) {
    .equipment_and_services_filter_button {
        position: relative;
    }
}
@media screen and (max-width: 400px) {
    .equipment_and_services_table_title {
        font-size: 5vw;
    }
}
