.collapse_button {
	position: absolute;
	left: -65px;
	margin-top: 18px;
	margin-left: 16px;
	transition: background-color 200ms, color 200ms;
	border-radius: 2px;
	color: #cacaca;
	border: 1px solid #cacaca;
	width: 36px;
	height: 20px;
	cursor: pointer;
	z-index: 7;
	&:hover {
		background-color: #cacaca;
		color: #fff;
	}
	// &::before {
	// 	content: "";
	// 	position: absolute;
	// 	left: -10px;
	// 	right: -10px;
	// 	top: -10px;
	// 	bottom: -10px;
	// }
	div {
		transition: transform 200ms, left 200ms;
		position: absolute;
		top: 8px;
		left: 14px;
		background-color: currentColor;
		width: 7px;
		height: 2px;
	}
	td {
		padding: 0 !important;
		border: 0 !important;
	}
}
.collapse_button_active {
	div:first-child {
		transform: rotate(-180deg);
	}
	div:last-child {
		transform: rotate(90deg);
	}
	div {
		width: 6px;
	}
}
@media screen and (max-width: 1340px) {
	.collapse_button {
		left: -22px;
		margin-top: 0;
		margin-left: 0;
		width: 20px;
		background-color: #cacaca;
		color: #fff;
		div {
			left: 8px;
		}
		&:hover {
			background-color: #e7e7e7;
			color: #868585;
		}
	}
}
@media screen and (max-width: 810px) {
	.collapse_button {
		left: 0;
	}
}
