@import "../../helpers/variable.scss";

.information_block_card {
	align-items: center;
	text-align: start;
	padding: 0 24px 0 35px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.information_block_container_wrapper {
	display: flex;
}
.information_block_wrapper {
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin-bottom: 10px;
}
.error_message {
	color: #cb3a39;
	cursor: pointer;
}
.card_button_block {
	display: flex;
	align-items: center;
	// flex-flow: wrap;
	justify-content: space-between;
	button {
		margin-left: 10px;
		padding: 0;
		text-transform: unset;
		color: black !important;

		&:hover {
			color: black !important;
		}
	}
	margin: 10px 0;
	// justify-content: space-between;
}
.information_user_block {
	font-weight: bold;
	font-size: 30px;
	color: black;
	align-items: center;
	display: flex;
	margin: 20px 0;
	flex-flow: wrap;
	button {
		margin-left: 15px;
	}
}
.information_user_block_size {
	font-size: 25px;
}
.information_container {
	margin-top: 20px;
}
.information_user_block_balance {
	width: 500px;
}
.card_button_block_1 {
	width: 130px;
}
.card_button_block_2 {
	width: 112px;
}
.card_button_block_3 {
	width: 185px;
}
.card_button_block_4 {
	width: 279px;
}
@media screen and (max-width: 660px) {
	.information_user_block_balance {
		width: 75vw;
	}
	.information_user_block {
		font-size: 4vw;
		justify-content: center;
		button {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 500px) {
	.card_button_block {
		font-size: 3.3vw;
	}
	.error_message {
		font-size: 3.3vw;
	}
	.card_button_block_1 {
		width: 26vw;
	}
	.card_button_block_2 {
		width: 21vw;
	}
	.card_button_block_3 {
		width: 37vw;
	}
	.card_button_block_4 {
		width: 56vw;
	}
	.information_user_block {
		button {
			padding: 2px 16px;
			// margin-top: 10px;
		}
	}
}
