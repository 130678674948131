.modal_content_wrapper{
    padding: 20px 35px;
    background: #f6f6f6;
    // position: relative;
    overflow-x: hidden;
    min-width: 320px;
}
.modal_button_block{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    flex-flow: wrap;
}
.modal_button_block_two{
    justify-content: space-around;
    flex-flow: wrap-reverse;
    button{
      margin: 5px;
    }
}
.modal_wrapper{
  position: relative;
}
.close {
    position: absolute;
    right: 8px;
    top: 6px;
    width: 32px;
    height: 32px;
    opacity: 0.9;
    cursor: pointer;
    transition: all 0.6s ease;
  }
  .close:hover {
    transform: rotate(90deg);
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 4px;
    background-color: #ff4f4f;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  @media screen and (max-width: 460px) {
    .modal_content_wrapper{
      min-width: auto;
    }
  }