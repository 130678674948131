.modal_content_head{
  text-align: center;
}
.modal_content_inform{
  margin:10px 0;
}
.modal_content_main{
  display: flex;
  flex-direction: column;
}
.modal_content_link{
  margin: 10px 0;
  text-align: end;
}