.login_block_content{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 35px;
}
.login_button_submit{
    margin-top: 15px !important;
}
.login_remember_me_container{
    margin-top: 12px;
}
.login_loading{
    color: red !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -12px;
}
.login_block_card{
    height: fit-content;
    width: 100%;
    form{
        width: 80%;
    }
}
.login_button_wraper{
    position: relative;
    margin-top: 13px;
    button{
        width: 100%;
    }
}
.login_container_wrapper{
    height: 100vh;
    align-items: center;
    display: flex !important;
}

.login_block_h1{
    display: contents;
    font-size: 1.2rem;
    font-weight: bolder;
    line-height: 1.334;
    letter-spacing: 0em;
}
.block_text{
    margin: 20px 0 0;
}
@media screen and (max-width: 400px) {
    .login_block_card{
    form{
        width: auto;
    }
}
}