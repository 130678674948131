@import "../../../../helpers/variable.scss";

@include colorPicker;

.desktop_version_table {
	display: table-row;
}

.desktop_version_table_cursor {
	cursor: pointer;
}

.link_style {
	text-decoration: none;
	color: blue;
}

[class*="prepare_order_paper"] {
	.desktop_version_table_unbind {
		td {
			text-decoration: line-through;
		}
		// td:before {
		//     content: "";
		//     position: absolute;
		//     top: 50%;
		//     left: 0;
		//     border-bottom: 1px solid #ca2a38;
		//     width: 98.5%;
		//     transform: translateX(9px);
		// }
	}
}

.desktop_version_table {
	position: relative;

	// td {
	//     // border-right: 1px solid #e1e1e1;
	// }

	&_email {
		min-width: 40px;
		cursor: pointer;
	}
}

.desktop_version_table_selected {
	background-color: rgba(110, 110, 110, 0.08);
}

.minus_icon {
	color: #ff4f4f;
}

.plus_icon {
	color: green;
}

.block_icon_img {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	svg {
		margin-right: 10px;
	}
}

@media only screen and (max-width: 810px) {
	.desktop_version_table {
		display: none !important;
	}
}
