.table_container {
	background-image: linear-gradient(
		to bottom,
		#109618,
		#4bab13,
		#74bf0c,
		#9dd303,
		#c6e600,
		#dcdc00,
		#efd200,
		#ffc700,
		#fda00b,
		#f37920,
		#e2522e,
		#ca2a38
	);

	tr {
		td:nth-child(2) {
			background-color: #fff;
		}
	}
}
