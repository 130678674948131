.info_container_wrapper {
	padding: 10px 0;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	display: flex;
	position: relative;
	min-height: 54px;
	// flex-direction: row-reverse;
}
.org_short_name {
	display: flex;
	// align-items: flex-end;
	// height: 100%;
}
.info_settings_link {
	position: absolute;
	right: 10px;
	top: 14px;
	.info_settings {
		color: #787878;
		cursor: pointer;
		&:hover {
			transition: color 1s;
			transition-property: color;
			color: black;
		}
	}
}

.info_item_content {
	margin: 5px 10px;
	div {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}
	span {
		font-weight: bold;
		font-size: 25px;
	}
}
.info_item_content_start {
	div {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}
	span {
		font-weight: bold;
		font-size: 21px;
	}
}
.block_wrapper_info {
	margin: 5px 10px;
	div {
		text-align: start;
	}
}
.info_item_content_home {
	width: 100%;
	justify-content: space-between;
	display: flex;
	margin: 5px 10px;
	padding-right: 25px;
	flex-flow: wrap;
	div {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	span {
		font-weight: bold;
		font-size: 25px;
	}
}
.info_item_saas {
	color: silver;
}
@media screen and (max-width: 510px) {
	.info_item_content {
		font-size: 4vw;
		span {
			font-size: 5vw;
		}
	}
	.font_size {
		font-size: 4vw;
	}
	.info_item_content_start {
		font-size: 4vw;
		span {
			font-size: 5vw;
		}
	}
	.info_item_content_home {
		font-size: 4vw;
		span {
			font-size: 5vw;
		}
	}
}
