@import "../../helpers/variable.scss";

@include colorPicker;

// .table_min {
// 	min-width: 750px;
// 	position: relative;
// 	// overflow: hidden;
// }
.container_wrapper {
	overflow-x: inherit;

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}
	&::-webkit-scrollbar {
		width: 12px;
		background-color: #f5f5f5;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #d62929;
	}

	.paper {
		margin-bottom: 16px;
	}

	.pagination {
		flex: 1 1 100%;
	}

	.information_block_card {
		text-align: start;
		padding: 0 24px 0 35px;
	}
	.card_button_block {
		display: flex;
		justify-content: space-between;
	}

	h1 {
		text-align: center;
	}

	.block_filter_all_wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.current_block_filter_wrapper {
		margin: 0px 2px 5px 3px;
		max-width: 100%;
		display: inline-block;
		white-space: nowrap;
		position: relative;
		overflow: hidden;
		font-size: 13px;
		line-height: 1;
		vertical-align: top;
		cursor: pointer;
		background: #eee;
		border-radius: 2px;
		-o-transition: background-color 0.1s ease-in-out;
		transition: background-color 0.1s ease-in-out;
		&:hover {
			background: #ddd;
		}
	}
	.current_block_filter_top {
		top: 0 !important;
		&::after {
			top: 6px !important;
		}
	}
	.current_block_filter {
		padding: 4px 20px 4px 10px;
		height: 18px;
		max-width: 200px;
		display: block;
		top: 3px;
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		align-items: center;
		&::after {
			content: "×";
			opacity: 0.5;
			position: absolute;
			top: 3px;
			right: 5px;
			font-size: 15px;
			// speak: none;
		}
		&:hover {
			text-decoration: line-through;
		}
	}
	.button_block {
		padding: 7px 0 15px;
		position: sticky;
		bottom: 0;
		background: #fff;
		border-radius: 0 0 5px 5px;
		box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.07);
		z-index: 8;

		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 15px;
	}
	.toolbar {
		margin-top: 20px;
		padding-left: 16px;
		padding-right: 8px;
		justify-content: center;
		flex-wrap: wrap;
		h6 {
			span {
				font-family: default_header;
				font-weight: 100;
				text-transform: uppercase;
			}
		}
	}
	.toolbar_selected {
		position: absolute;
		left: 12px;
		font-size: 10px;
		bottom: 0;
		font-weight: bold !important;
		color: #ff4f4f;
	}
	.active_toolbar {
		color: #f50057;
		background-color: rgb(255, 226, 236);
	}

	.filter_button {
		position: absolute !important;
		right: 10px;
	}
	.table_container {
		position: relative;
		td {
			padding: 8px;
			border: 1px solid rgba(224, 224, 224, 1);
		}
	}
	.minimize_padding {
		td {
			padding: 2px 4px;
			font-size: 0.8rem;

			&:nth-child(2),
			&:last-child {
				width: 100%;
				max-width: 15%;
			}
		}
	}
	.mobile_card_wrapper {
		.mobile_card_row {
			margin: 8px 8px;
			display: flex;
		}
		.mobile_card_name {
			margin-right: 5px;
			font-weight: bold;

			white-space: nowrap;
		}
	}
	.mobile_version_table {
		display: none;
	}

	.filter_empty_text {
		text-align: center;
	}
	.reset_filter {
		cursor: pointer;
	}

	.date_block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 11px 20px;

		&_period {
			display: flex;
			gap: 10px;

			&_from,
			&_to {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}
		&_confirm {
			flex: 1 1 100%;
			padding-top: 40px;
			display: flex;
			justify-content: center;
			gap: 5px;
		}

		.btns_wrapper {
			width: 100%;
			justify-content: space-between;
		}
	}

	.head_table_row_align {
		text-align: center;
		border-top: 1px solid #e1e1e1;
		white-space: nowrap;
		font-size: 12px !important;
		line-height: normal !important;
	}

	.head_table_row_padding {
		padding: 16px;
	}
	.head_table_row_none_padding {
		padding: 0 !important;
	}

	.select_visable {
		display: none !important;
	}

	.batch_tran_table {
		background-color: rgba(128, 128, 128, 0.1);
	}

	.analytic_tabs,
	.analytic_charts {
		justify-content: center;
	}

	.orderListBtn_wrapper {
		padding: 30px 20px 20px;
		text-align: end;
	}

	@media screen and (max-width: 810px) {
		.mobile_version_table {
			display: table-row;
		}
		.table_min {
			min-width: auto;
		}
		.date_block {
			flex-direction: column;
			gap: 20px;

			&_period_from,
			&_period_to {
				flex-direction: column;
				align-items: flex-start;
			}

			&_confirm {
				padding-top: 20px;
			}
		}
		.orderListBtn_wrapper {
			text-align: center;
		}
	}

	@media screen and (min-width: 681px) {
		.head_table_email,
		.head_table_checkbox {
			width: 48px;
			padding: 8px !important;
			text-align: center !important;
			border-top: 1px solid #e1e1e1;
		}
	}

	@media screen and (max-width: 680px) {
		.head_table_email,
		.head_table_checkbox {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.head_table_wrapper {
			display: flex;
			flex-direction: column;
		}
		.head_table_row_none_padding {
			padding: 5px;
		}
		.head_table_row_padding {
			padding: 5px;
		}
		.head_table_row_align {
			text-align: left;
			margin-left: 10px;
			display: none !important;
		}
		.select_visable {
			display: flex !important;
			width: 100%;
			margin-right: 10px;
		}
	}

	@media screen and (max-width: 460px) {
		.filter_button {
			position: relative !important;
		}
	}

	@media screen and (max-width: 400px) {
		.tab {
			font-size: 12px;
		}
		.title {
			font-size: 5vw;
		}
	}
}
