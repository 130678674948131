.container_wrapper {
	margin: 20px 0;
	padding: 15px 20px;
}
.container_heading {
	text-align: center;
}
.container_info_block_wrapper {
	display: flex;
	justify-content: flex-end;
}
.container_info_block {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}
.button_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.button_block {
	align-items: center;
	display: flex;
	gap: 10px;

	&_users {
		margin-top: 10px;
		justify-content: flex-end;
	}
}
.text_block {
	display: flex;
	align-items: center;

	&_email {
		flex-wrap: wrap;
		// flex-direction: column;
		// align-items: flex-start;

		.button_block {
			flex-wrap: wrap;
			// flex-direction: column;
			// align-items: flex-start;
			gap: 15px;
			width: 100%;
		}
	}
}
.switch_style_active {
	[class*="Mui-checked"] {
		color: #fd3030 !important;
	}

	[class*="Mui-disabled"] {
		color: #e0e0e0 !important;
	}

	[class*="MuiSwitch-track"] {
		background-color: #f50000 !important;
	}
}

@keyframes blockOn {
	0% {
		opacity: 0;
		height: 0%;
	}
	100% {
		opacity: 1;
		height: 100%;
	}
}
@media screen and (max-width: 500px) {
	.button_block {
		flex-direction: column;
		align-items: flex-start;
	}
}
