@import "../../helpers/variable.scss";

.footer_container {
	background: #534c5c;
	color: rgb(255, 255, 255);

	// height: $footerHeight;
	align-items: center;
	display: flex;
	justify-content: space-evenly;

	position: relative;
	z-index: 3;
	padding: 15px;

	@media screen and (max-width: 330px) {
		font-size: 14px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}

	span {
		font-family: "default_header";
	}

	&__left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@media screen and (max-width: 768px) {
			padding-bottom: 10px;
		}
	}
	&__right {
		display: flex;
		align-items: center;

		.footer_date {
			margin-right: 20px;
		}

		.footer_lang {
			display: flex;
			align-items: center;
		}
	}
}
.version {
	position: absolute;
	bottom: 0;
	left: 0;
	color: white;
}
