@mixin firstBlock {
    width: 60%;
    // text-align: initial;
    text-align: end;
}
$colorGreen: green;
$colorRed: #ff4f4f;

.history_block_information_wrapper {
    align-items: flex-start;
    margin-top: 20px;
    padding: 0 20px;
    h2 {
        font-family: "default_header";
        text-transform: uppercase;
        font-weight: 100;
    }
}
.plus_icon {
    color: $colorGreen;
}
.minus_icon {
    color: $colorRed;
}
.sum_plus {
    color: $colorGreen;
    @include firstBlock;
}
.sum_minus {
    color: $colorRed;
    @include firstBlock;
}
.content_wrapper {
    display: flex;
    margin-left: 20px;
    width: 100%;
    align-content: safe;
}
.content_block_wrapper {
    display: flex;
    margin-bottom: 10px;
    margin: 10px auto;
    div {
        color: rgba(0, 0, 0, 0.87);
    }
}
.history_block_information {
    max-width: 600px;
    margin: auto;
    margin-bottom: 20px;
}
.block_buttom_wrapper {
    text-align: end;
    margin-left: 20px;
}
.content_order {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}
.content_order_link {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    color: blue;
    text-decoration: none;
}
.content_order_table {
    display: flex;
    white-space: nowrap;
}
.content_order_link_table {
    display: flex;
    color: blue;
    text-decoration: none;
    white-space: nowrap;
}

.table_wrapper {
    width: 100%;
    margin-left: 16px;
    tr {
        margin: 8px 0;
    }
    td:nth-child(1) {
        width: 20%;
    }
    td:nth-child(2) {
        width: 40%;
    }
    td:nth-child(3) {
        width: 30%;
    }
}
.title_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 550px) {
    @mixin firstBlock {
    }
    .content_wrapper {
        flex-direction: column;
        align-items: flex-start;
        td{
            width: auto !important;
        }
    }
    .content_order {
        justify-content: flex-start;
    }
    .content_order_link {
        justify-content: flex-start;
    }
    .block_buttom_wrapper {
        text-align: center;
    }
}
