.prepare_order_pay_panel {
	min-height: 0 !important;
	height: 0;
}
.rows_wrapper {
	border-bottom: none;
	// color: silver;
}
.prepare_order_pay_error_message_rows {
	border-bottom: none;
	color: silver;
}
.switch_wrapper {
	display: flex;
	align-items: center;
	margin-left: -11px;
}
.switch_style_active {
	[class*="Mui-checked"] {
		color: #fd3030 !important;
	}
	[class*="Mui-disabled"] {
		color: #e0e0e0 !important;
	}
	[class*="MuiSwitch-track"] {
		background-color: #f50000 !important;
	}
}
.style_select {
	margin-left: 10px;

	fieldset {
		outline: none;
	}

	[class*="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input"] {
		padding: 7px 14px;
	}
}
[class*="MuiInputBase-root MuiOutlinedInput-root prepareOrderPay_style_select__1s89z Mui-focused Mui-focused MuiInputBase-formControl"] {
	[class*="PrivateNotchedOutline-root-96 MuiOutlinedInput-notchedOutline"] {
		border: 1px solid #0000003b !important;
	}
}
.prepare_order_inform__wrapper {
	padding-left: 11px;

	.prepare_order_inform__period,
	.prepare_order_inform__date_block {
		display: flex;
		align-items: center;
		height: 50px;
	}

	.prepare_order_inform__date_block {
		button {
			margin: 0 7px;
		}
	}
}

.prepare_order_pay_paper_wrapper {
	margin-bottom: 16px;
	margin-top: 20px;
	position: relative;
	padding-top: 10px;
}
.prepare_order_pay_table_min {
	min-width: 750px;

	td {
		border: 1px solid rgba(224, 224, 224, 1);
	}
}
.prepare_order_pay_error_message {
	color: #c90011;
	display: flex;
	align-items: center;
	padding-left: 30px;

	svg {
		margin-right: 5px;
	}
}
.block_inform_container_wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
	flex-flow: wrap;
}
.button_block {
	padding: 20px 0;
	text-align: center;

	[class*="MuiAlert-message"] {
		flex-direction: row;
		flex-wrap: wrap;
	}

	button {
		margin: 5px 20px;
		min-width: 100px;
	}
}
.price_order_wrapper {
	display: flex;
	align-items: center;
	margin-right: 13px;
	margin-left: 13px;
	height: 50px;
}
.card_style_support_wrapper {
	position: relative;

	button {
		margin-left: 10px;
		width: 20px;
		height: 20px;
		min-height: 20px;
		margin-bottom: 4px;
	}
}
.card_style_support {
	position: absolute;
	top: -115px;
	padding: 10px;
	height: 100px;
	width: 300px;
	right: 3%;
	box-shadow: 0 3px 22px rgba(0, 0, 0, 0.47);
}

@media screen and (max-width: 880px) {
	.prepare_order_pay_table_min {
		min-width: auto;
	}
}

@media screen and (max-width: 810px) {
	.table_head_wrapper {
		display: none;
	}
	.prepare_order_pay_error_message {
		padding: 0px 16px 16px 16px;
	}
}

@media screen and (max-width: 415px) {
	.prepare_order_date_block {
		font-size: 3.9vw;
	}
}
